<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1  -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            REGISTER
          </h2>
        </b-link>

        <b-card-title class="mb-1" />
        <b-card-text class="mb-2" />

        <!-- form -->
        <b-form
          class="auth-register-form mt-2"
          @submit.prevent="signup"
        >

          <!-- userId -->
          <b-form-group label="ID" label-for="userId">
            <b-form-input
              id="userId"
              v-model="registData.userid"
              name="register-userId"
              placeholder="ID"
              @blur="handleBlur"
            />
            <small v-if="userIdError" class="text-danger">{{ userIdError }}</small>
          </b-form-group>
          <!-- password -->
          <b-form-group
              label="Password"
              label-for="password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                  id="password"
                  v-model="registData.password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  name="register-password"
                  placeholder="············"
                  @blur="validatePassword"
              />
              <b-input-group-append is-text>
                <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small v-if="passwordError" class="text-danger">{{ passwordError }}</small>
          </b-form-group>

          <!-- nickname -->
          <b-form-group
            label="닉네임"
            label-for="nickname"
          >
            <b-form-input
              id="nickname"
              v-model="registData.nickname"
              name="register-nickname"
              placeholder="johndoe"
            />
          </b-form-group>

          <!-- email -->
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="registData.email"
              name="register-email"
              placeholder="john@example.com"
              @blur="validateEmail"
            />
            <small v-if="emailError" class="text-danger">{{ emailError }}</small>
          </b-form-group>

          <!-- refer code -->
          <b-form-group
            label="추천코드"
            label-for="recommendCode"
          >
            <div class="d-flex align-items-center">
              <b-form-input
                id="recommendCode"
                v-model="registData.recommendCode"
                name="register-recommendCode"
                placeholder="johndoe"
              />
              <b-button
                v-if="false"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="isValidRecommendCode ? 'primary' : 'secondary'"
                style="margin-left: 1rem; min-width: 5rem;"
                :disabled="!isValidRecommendCode"
                @click="handleValid('recommendCode')"
              >
                확인
              </b-button>
            </div>
          </b-form-group>

          <!-- submit button -->
          <b-button
            variant="primary"
            block
            type="submit"
          >
            Sign up
          </b-button>
        </b-form>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{name:'login'}">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import axios from 'axios'
import _ from 'lodash'

import {CHECK_USER_INFO, SIGNUP} from '@/store/auth/action'
import { createNamespacedHelpers } from 'vuex'

const authStore = createNamespacedHelpers('authStore')

export default {
  name: 'Signup',

  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,

      registData: {
        site: null,
        userid: null,
        password: null,
        nickname: null,
        email: null,
        recommendCode: null,
        enrollIp: null,
      },
      userIdError: '',
      emailError: '',
      passwordError: '',
    }
  },
  computed: {
    ...authStore.mapGetters({
      chkeckUserInfo: 'chkeckUserInfo',
    }),
    isValidRecommendCode() {
      return this.registData.recommendCode && this.registData.recommendCode.trim().length >= 4
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    this.registData.site = this.$site
    this.registData.enrollIp = localStorage.getItem('ip')
  },
  methods: {
    ...authStore.mapActions({
      $signup: SIGNUP,
      $chkUserInfo: CHECK_USER_INFO,
    }),
    signup() {
      const errors = [this.userIdError, this.emailError, this.passwordError]

      if (errors.every(error => error === '')) {
        this.submit()
      }
    },

    submit() {
      Swal.fire({
        title: '가입 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes()
        }
      })
    },

    async confirmYes() {
      await this.$signup({
        ...this.registData,
      })
        .then(res => {
          switch (res) {
            case 1:
              Swal.fire({
                title: '완료 되었습니다.',
                icon: 'success',
                timer: 5000,
                background: 'rgb(3,11,37)',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => {
                this.reset()
                this.$router.push({ name: 'login' })
              })
              break
            case 0:
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: '이미 등록된 정보가 있습니다. ',
                  icon: 'EditIcon',
                  variant: 'error',
                },
              })
              break
            case -99:
            default:
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: '등록실패!! (관리자에게 문의바랍니다.)',
                  icon: 'EditIcon',
                  variant: 'error',
                },
              })
              break
          }
        })
    },

    async handleValid(data) {
      console.log(data, this.registData[data])
    },
    reset() {
      this.registData.userid = null
      this.registData.password = null
      this.registData.nickname = null
      this.registData.email = null
      this.registData.recommendCode = null
    },

    validateUserId() {
      const inputValue = this.registData.userid

      // 입력 값이 null 또는 빈 문자열이면 에러 메시지 설정
      if (!inputValue || inputValue.trim() === '') {
        this.userIdError = 'ID를 입력해주세요.'
        return
      }

      // 영문자와 숫자만 허용하는 정규식
      const allowedChars = /^[a-zA-Z0-9]*$/

      // 유효하지 않은 문자가 포함된 경우 에러 메시지 설정
      if (!allowedChars.test(inputValue)) {
        this.userIdError = 'ID는 영문자와 숫자만 입력 가능합니다.'
      } else if (inputValue.length < 4) {
        // 길이 체크: 4자 미만일 경우
        this.userIdError = 'ID는 최소 4자 이상이어야 합니다.'
      } else {
        // 모든 조건을 통과하면 에러 메시지를 비움
        this.userIdError = ''
      }
    },

    async handleBlur() {
      // @blur 이벤트에서 validateUserId를 호출
      const { userid } = this.registData
      this.validateUserId()
      if (!this.userIdError) {
        if (!this.registData.userid) {
          this.userIdError = 'ID를 입력해주세요.'
        }
        try {
          await this.$chkUserInfo({
            userid,
          })
          if (this.chkeckUserInfo) {
            this.userIdError = ''
          } else {
            this.userIdError = '사용할 수 없는 ID 입니다.'
          }
        } catch (error) {
          // console.error(error)
          this.userIdError = '관리자에게 문의 바랍니다.'
        }
      }
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // 이메일 형식 정규식
      const { email } = this.registData

      // 이메일이 올바른 형식인지 확인
      if (!emailPattern.test(email)) {
        this.emailError = '올바른 이메일 형식이 아닙니다.'
      } else {
        this.emailError = ''
      }
    },
    validatePassword() {
      const { password } = this.registData

      if (password.length < 4) {
        this.passwordError = '비밀번호는 최소 4자 이상이어야 합니다.'
      } else {
        this.passwordError = ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
